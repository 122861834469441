import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import SEO from "../components/SEO"
import Hero from "../components/hero"
import linkResolver from "../utils/linkResolver"
import { ArrowScrollToTop } from "../components/arrowScrollToTop"

const LegalPage = ({ data, pageContext: { locale }, location }) => {
  const LegalPageData = data.prismic.legalpage
  return (
    <>
      <SEO
        title={LegalPageData.meta_title}
        desc={LegalPageData.meta_description}
        metadata={data.site.siteMetadata}
        locale={locale}
        location={location}
        pathname={location.pathname}
      />
      <Hero
        hero={{
          title: LegalPageData.title,
          subtitle: LegalPageData.subtitle,
          image: {
            image: LegalPageData.main_image,
            sharp: LegalPageData.main_imageSharp,
          },
        }}
      />
      <section className="section is-medium">
        <div className="container">
          <div className="columns is-centered">
            <div className="column hasRichText is-10">
              {RichText.render(LegalPageData.richtext, linkResolver)}
            </div>
          </div>
        </div>
      </section>
      <ArrowScrollToTop />
    </>
  )
}

export default LegalPage

LegalPage.propTypes = {
  data: PropTypes.shape({
    prismic: PropTypes.object.isRequired,
  }).isRequired,
}

export const LegalQuery = graphql`
  query LegalQuery($uid: String!, $locale: String!) {
    prismic {
      legalpage(uid: $uid, lang: $locale) {
        meta_title
        meta_description
        title
        subtitle
        richtext
      }
    }

    site {
      ...SiteInformation
    }
  }
`
